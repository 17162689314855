import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from './components/LogIn'
import ForgotPassword from './components/ForgotPassword'
import TrialRegistration from './components/TrialRegistration'
import TrialExpired from './components/TrialExpired'

import RoadMapRoute from './modules/admin/routes/roadmap'
import ClientRoute from './modules/client/routes/roadmap'
import ClientDashboardRoute from './v2/modules/client/course/route'

import InternalHome from '@/modules/internal/dashboard/routes/index'
import ClientDashboard from './v2/modules/admin/courses/routes'
import SharedCourseLesson from './v2/modules/client/course/route/sharedcourselesson.js'

// import TestAsana from './components/TestAsana.vue'
import fileuploader from './v3/routes/updates/fileuploader'
import ClientCoursePageRoute from './v3/pages/admin/courses/client/route'

Vue.use(VueRouter)

let routes = 
[
    {
        path: '/',
        name: 'index',
        component: Login
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword
    },
    {
        path: '/trial-registration',
        name: 'trial-registration',
        component: TrialRegistration
    },
    {
        path: '/trial-expired',
        name: 'trial-expired',
        component: TrialExpired
    },
]

const router = new VueRouter({
    mode: 'history',
    routes : [
        ...routes,
        ...RoadMapRoute,
        ...ClientRoute,
        ...InternalHome,
        ...ClientDashboard,
        ...ClientDashboardRoute,
        ...SharedCourseLesson,
        // Updates
        ...fileuploader,
        ...ClientCoursePageRoute      
    ]
})

export default router
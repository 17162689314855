import RoadmapClass from "../classes/roadmap.class";

export default class RoadmapMethods
{
    constructor(parameters = {}) {
        this.roadmap_class = new RoadmapClass();

        // Parameters
        this.selected_roadmap = parameters.selected_roadmap
        this.dialogtrelloroadmap = parameters.dialogtrelloroadmap
    }

    methods = ( () => {

        const getRoadmapStaffs = (course_id, client_id) => {
            this.roadmap_class.getClientStaffs(course_id, client_id);
        }

        const selectBillableStaff = async (id, is_checked, course_id, client_id) => {
            await this.roadmap_class.selectRoadmapBillableStaff(id, is_checked);
            getRoadmapStaffs(course_id, client_id)
        }

        const getRoadmaps = async (user_id) => {
            await this.roadmap_class.getRoadmapDataByUser(user_id)
        }

        const setRoadmapCardAsCompleted = async (data, is_checked) => {
            this.roadmap_class.setRoadmapCardAsCompleted(data, is_checked);
        }

        /** Selection of Roadmap to edit */
        const selectedRoadmap = (roadmap) => {
            this.dialogtrelloroadmap.value = true
            this.selected_roadmap.value = roadmap
        }

        const closeEditRoadmapDialog = () => {
            this.dialogtrelloroadmap.value = false
            this.selected_roadmap.value = null
        }

        return { 
            getRoadmapStaffs, selectBillableStaff, getRoadmaps,
            setRoadmapCardAsCompleted, selectedRoadmap,
            closeEditRoadmapDialog
         }
    })()
}
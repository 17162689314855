import Vue from "vue";

export default class Course {
    constructor() {
        this.user = null        
    }

    set_user( user ) {
        this.user = user;
    }
    get_user() {
        return this.user;
    }

    async get_all_courses(state) {
        await Vue.axios.get(`admin/course/get_courses`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    state.dispatch('course/set_all_courses_action', result.data)
                } else {
                    console.log( result.data )
                }
            } else {
                console.log( data.message ) 
            }
        }).catch( (err) => {
            console.log(err)
        })
    }

    generateCourseLessonLink(users_id, lesson_id, store) {
        Vue.axios.patch(`admin/course/generate_course_lesson_link/${users_id}/${lesson_id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    const link = result.data
                    navigator.clipboard.writeText(window.location.protocol + '//' + window.location.host + '/sharedcourselesson/?link=' +link)
                        .then(() => {
                            store.commit("auth/setMessage", 
                            {show: true, message: 'Generated a link for a lesson to be shared to other users.'}, 
                            {root: 1})
                    }, () => {
                        console.log('Please try again!')
                    });
                } else {
                    console.log(result.data)
                }
            } else {
                //
            }
        }).catch( (err) => {
            console.log(err)
        })
    }
}
<template>
    <v-container fluid>
        <v-card>
            <v-card-title class="v-card-title-border">{{ account.name }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-btn-toggle v-model="client_account_tab" 
                    tile
                    color="deep-purple accent-3"
                    group class="mb-6">
                    <v-btn value="course" @click="methods.moveToClientPages(`/admin/client/course/${account.id}`)">
                        <v-icon class="mr-2">mdi-school-outline</v-icon>
                        Course
                    </v-btn>
                    <v-btn value="roadmap">
                        <v-icon class="mr-2">mdi-sitemap</v-icon>
                        Roadmap
                    </v-btn>
                    <v-btn value="clientanalytics" @click="methods.moveToClientPages(`/admin/client/analytic/${account.id}`)">
                        <v-icon class="mr-2">mdi-monitor-eye</v-icon>
                        Activity Feeds
                    </v-btn>
                </v-btn-toggle>
            </v-card-text>
            <v-card-text>
                <RoadmapComponent :staff="roadmap_computed_properties.getBillableStaff.value"
                    :client_id="account.id"></RoadmapComponent>
            </v-card-text>
        </v-card>        
    </v-container>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue2-helpers/vue-router';
import CourseMethods from './modules/methods/course.method'
import CourseComputed from './modules/computed/course.computed';

import RoadmapMethods from './modules/methods/roadmap.method';
import RoadmapComputed from './modules/computed/roadmap.computed';

import RoadmapComponent from './subcomponents/roadmap/RoadmapComponent.vue';

import store from '@/states'

// Reference Variables
const account = ref('')
const selected_course = ref()
const enrolled_courses = ref()
const client_account_tab = ref('roadmap')

// Common Variables
const router = useRouter()
const route = useRoute()

// Classes
const method_class = new CourseMethods({})
const computed_class = new CourseComputed({
    enrolled_courses: enrolled_courses,
    selected_course: selected_course
})

const roadmap_method_class = new RoadmapMethods()
const roadmap_computed_class = new RoadmapComputed()

// Methods
const methods = method_class.methods
const roadmap_methods = roadmap_method_class.methods

// Computed
const computed_properties = computed_class.computed_properties
const roadmap_computed_properties = roadmap_computed_class.computed_properties

onMounted( () => {
    const client_id = route.params.client_id
    const client_list = store.state.adminmain.clientlist    
    const selected_client = client_list.find( (client) => client.id == client_id)
    
    if ( typeof selected_client == 'undefined' ) {
        router.push({path: '/clientlist'})
    }
    enrolled_courses.value = selected_client.course_id
    account.value = selected_client
    methods.getAllCourses()

    roadmap_methods.getRoadmapStaffs(selected_client.course_id[0], client_id)
})
</script>
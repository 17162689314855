import { render, staticRenderFns } from "./QuarterlyTasks.vue?vue&type=template&id=3b469504&scoped=true&"
import script from "./QuarterlyTasks.vue?vue&type=script&lang=js&"
export * from "./QuarterlyTasks.vue?vue&type=script&lang=js&"
import style0 from "./QuarterlyTasks.vue?vue&type=style&index=0&id=3b469504&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b469504",
  null
  
)

export default component.exports
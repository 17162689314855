<template>
    <div style="min-width: 1500px;" class="d-flex" v-if="loading == false">
        <div style="min-width: 100px;margin-right:10px;">
            <v-card class="mx-1 grey lighten-3" style="min-width: 100px">
                <v-card-title class="justify-center v-card-title black--text">
                    Billable Staff
                </v-card-title>
                <v-card-text 
                    style="padding: 0;padding-bottom:5px">
                    <v-card
                        no-gutters
                        v-for="(staff, index) in props.staff.get_child"
                        :key="index"
                        style="margin: 5px; height: 92px; cursor: pointer;"
                        :class="`my-0 rounded-0 elevation-0 pb-4 pt-1 black--text ${staff.ischecked == true ? 'selected-staff' : ''}`"
                        v-model="staff.ischecked"
                        @click="roadmap_methods.selectBillableStaff(staff.id, staff.ischecked, staff.course_id, staff.user_id)">
                        <v-card-title class="v-card-title justify-center py-6">
                            {{ staff.people }}
                        </v-card-title>
                    </v-card>
                </v-card-text>
            </v-card>
        </div>                
        <div v-for="(roadmap, index) in roadmap_computed.getRoadmapData.value"
            no-gutters
            style="min-width: 200px;"
            :key="index">
            <v-card no-gutters 
                class="mx-1 grey lighten-3">
                <v-card-title class="justify-center v-card-title black--text">
                    {{ roadmap.name }}
                </v-card-title>
                <v-card-text style="padding: 0; padding-bottom: 5px;">
                    <v-card no-gutters
                        v-for="(child, index) in roadmap.get_child"
                        :key="index"
                        style="margin: 5px; padding-right: 10px; cursor: pointer;"
                        :style="`border-left:5px solid ${
                            child.ischecked == true
                            ? '#0F6B00'
                            : child.color == null
                            ? '#FFF'
                            : child.color
                        }`" @click="roadmap_methods.selectedRoadmap(child)">
                        <v-card-title class="v-card-title">
                            <div class="d-inline">
                                <v-simple-checkbox
                                    v-model="child.ischecked"
                                    :ripple="false"
                                    @click="roadmap_methods.setRoadmapCardAsCompleted(child, child.ischecked)">
                                </v-simple-checkbox>
                            </div>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="v-card-child-title-name d-inline"
                                        v-bind="attrs"
                                        v-on="on">
                                        {{ child.name }}
                                    </div>
                                </template>
                                <span>{{ child.name }}</span>
                            </v-tooltip>
                        </v-card-title>
                        <v-card-text
                            class="no-gutter
                                d-flex
                                flex-wrap
                                justify-space-between
                                v-card-text-font-size"
                                style="padding-left: 3px; height: 45px">
                                <div>
                                    <v-icon small>mdi-clock-time-seven-outline</v-icon>
                                    {{ child.deadline_date ? design_methods.getPriorityDate(child.deadline_date) : '---' }}
                                    <div>
                                        <v-icon small>mdi-progress-alert</v-icon>
                                        {{ child.progress ? child.progress + '%' : 'Not Set' }}
                                    </div>   
                                </div>                                                                    
                                <div class="d-flex align-end">
                                    <v-avatar
                                        v-if="design_methods.getAssignedUserInitials(child.tagged_to) == '---' ? false : true"
                                        color="primary"
                                        size="26"
                                        class="white--text mb-1"
                                        style="font-size: 12px; margin-left: 5px">
                                        {{ design_methods.getAssignedUserInitials(child.tagged_to) }}
                                    </v-avatar>
                                </div>                            
                        </v-card-text>
                    </v-card>
                </v-card-text>                
            </v-card>
        </div>
        <EditRoadmapCarComponent
            :edit_roadmap_card_dialog="dialogtrelloroadmap"
            :roadmap_data="selected_roadmap"
            @closeEditRoamdapDialog="roadmap_methods.closeEditRoadmapDialog()">
        </EditRoadmapCarComponent>
    </div>
    <div v-else class="text-center">
        <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate>
        </v-progress-circular>
        <div class="mt-6">Please wait.</div>
        <div>We are pulling all your roadmap data from the server.</div>
    </div>
</template>

<script setup>
import { onMounted, ref, defineProps, watch } from 'vue'

import RoadmapMethods from '../../modules/methods/roadmap.method'
import RoadmapComputed from '../../modules/computed/roadmap.computed'

/** Images */
import ProgressIconGray from "@/assets/roadmaps/progress-gray-icon.svg";
import ProgressFortyOneToSeventyIcon from "@/assets/roadmaps/progress-orange-icon.svg";
import ProgressCompletedIcon from "@/assets/roadmaps/progress-completed-icon.svg";

import EditRoadmapCarComponent from './EditRoadmapCardComponent.vue'

// Props and Emit
const props = defineProps(['staff', 'client_id'])

// Reference Variables
const loading = ref(false)
const dialogtrelloroadmap = ref(false)
const selected_roadmap = ref({})

// Classes
const roadmap_method_class = new RoadmapMethods({
    selected_roadmap: selected_roadmap,
    dialogtrelloroadmap: dialogtrelloroadmap
})
const roadmap_computed_class = new RoadmapComputed()

// Methods
const roadmap_methods = roadmap_method_class.methods

// Computed
const roadmap_computed = roadmap_computed_class.computed_properties

/** Design Methods */
const design_methods = ( () => {

    const setProgresssIcon = (progress, is_progres_completed) => {
        if (is_progres_completed == true) {
            return ProgressCompletedIcon;
        } else {
            if (progress >= 0 && progress <= 40) {
                return ProgressIconGray;
            } else if (progress >= 41 && progress <= 70) {
                return ProgressFortyOneToSeventyIcon;
            } else {
                return ProgressCompletedIcon;
            }
        }
    }

    const getAssignedUserInitials = (username) => {
      return username == null ? "---" : username
        .split(" ")
        .map((x) => x[0])
        .join("");
    }

    const getPriorityDate = (date) => {
        let temp = date.split(' ');
        
        // date
        let tempdate = temp[0].slice(0, 3).toUpperCase();
        let tempday = temp[1];

        return (tempdate + ' ' + tempday);
    }

    return {setProgresssIcon, getAssignedUserInitials, getPriorityDate}
})()

onMounted( () => {
    
});

watch(props, async () => {
    loading.value = true
    await roadmap_methods.getRoadmaps(props.client_id)
    loading.value = false
})
</script>

<style lang="scss" scoped>
    .no-gutter {
        margin: 0;
        padding: 0;
    }

    .v-card-title {
        margin: 0;
        padding: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 14px;
        font-weight: bold;
    }

    .v-card-child-title-name {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
    }

    .v-card-text-deadline-date-icon {
        margin-right: 2px;
        margin-left: 5px;
    }

    .v-card-text-font-size {
        font-size: 11px;
    }

    .v-card-text-div {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .selected-staff {
        background-color: #479DFF;
        color: white !important;
    }
</style>